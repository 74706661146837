import { DropdownOptionProps } from '@teamfabric/copilot-ui';
export const VALIDATION_VALUE_COMPONENT_TYPE = {
  //'Is exact match', 'Includes', 'Excludes'
  string: [
    { id: 'isExactMatch', label: 'Is exact match' },
    { id: 'includes', label: 'Includes' },
    { id: 'excludes', label: 'Excludes' },
  ] as DropdownOptionProps[],
  double: [
    { id: 'isEqualTo', label: 'Is equal to' },
    { id: 'isGreaterThan', label: 'Is greater than' },
    { id: 'isLesserThan', label: 'Is lesser than' },
  ] as DropdownOptionProps[],
};
