import { MultiSelectProps } from '@teamfabric/copilot-ui';
import { MultiSelectOptionProp } from '@teamfabric/copilot-ui/dist/components/multiSelect';
import { UseFieldArrayRemove } from 'react-hook-form';

export type ControlledMultiSelectProps<ValueT> = Omit<
  MultiSelectProps,
  'onChange' | 'onClear'
> & {
  fieldValueToOption?: (
    value: ValueT,
    options: MultiSelectOptionProp[]
  ) => MultiSelectOptionProp[];
  optionToFieldValue?: (
    option: MultiSelectOptionProp[],
    options: MultiSelectOptionProp[]
  ) => ValueT;
};

export interface CommonFormProps {
  loading?: boolean;
  disabled?: boolean;
  name?: string;
  blockDraggable?: boolean;
  id?: string;
  dragged?: boolean;
  index?: number;
  value?: any;
  remove?: UseFieldArrayRemove;
}

export interface LandingFilters {
  orders?: any;
}

export enum PRICE_FORMAT {
  CURRENCY,
  SYMBOL,
  SYMBOL_AND_CURRENCY,
  NO_SYMBOL,
}

export interface DropdownOption {
  id: string;
  value: string | number;
  label: string;
  additionalData?: any;
}

export type OrderIdentifier = {
  orderId?: string;
  orderNumber?: string;
  append?: string;
};

export enum PollUploadStatusEnum {
  FINISHED = 'FINISHED',
  FILE_ERROR = 'FILE_ERROR',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  PROCESSING = 'PROCESSING',
  INITIATED = 'INITIATED',
}
