import { FabAIObservables } from '@copilot/mfa-communication';
import { MESSAGE_ID_CHATBOX_KEY, constructRouteChatAI } from 'ds4/utils/fabAI';
import { isEqual } from 'lodash';
import { useEffect } from 'react';

const AppChatboxController = ({ router }) => {
  useEffect(() => {
    // Subscribe to the data from the chatbot
    FabAIObservables.RxJsService.receiveDataFromChatbot().subscribe({
      next: data => {
        console.log('Init chatbox orders', FabAIObservables.RxJsService, data);
        if (data) {
          const { routeDetails, entities, actionStep, messageId } =
            data?.data?.[0];

          const { suggestionEventId } = data;

          console.log('Received data from chatbot:', data);

          if (
            isEqual(sessionStorage.getItem(MESSAGE_ID_CHATBOX_KEY), messageId)
          )
            return;

          const path = constructRouteChatAI({ routeDetails, actionStep });

          if (path) {
            router.navigate(path, {
              state: { ...entities, suggestionEventId },
            });
            sessionStorage.setItem(MESSAGE_ID_CHATBOX_KEY, messageId);
          }
        }
      },
    });
  }, []);

  return null;
};

export default AppChatboxController;
